import { SUBSCRIPTIONS_KEY } from 'core/api/CoreHttpRepo';
import useBaseMutation from 'core/domain/interface/hooks/useBaseMutation';
import Cart from 'payment/api/dto/Cart';
import Checkout from 'payment/api/dto/Checkout';
import UppdatePaymentEmail from 'payment/api/dto/UpdatePaymentEmail';
import httpRepo, {
  ADD_DAYS_KEY,
  PAYMENT_EMAIL_KEY, REDEEM_END_OF_SEASON_OFFER_KEY,
  SEND_TO_CART_FROM_GIFT_KEY, SEND_TO_CART_KEY, SEND_TO_CHECKOUT_KEY,
} from 'payment/api/PaymentHttpRepo';

const useSendPaymentUpdateEmail = () => {
  const mutation = httpRepo.useSendPaymentUpdateEmail();

  const baseMutation = useBaseMutation(
    PAYMENT_EMAIL_KEY,
    mutation,
  );

  const submit = (updatePaymentVariables: UppdatePaymentEmail, onSuccess: () => void) => {
    baseMutation.submit(updatePaymentVariables, { onSuccess });
  };

  return { isSubmitting: baseMutation.isSubmitting, submit };
};

const useSendToCheckout = () => {
  const baseMutation = useBaseMutation(
    SEND_TO_CHECKOUT_KEY,
    httpRepo.useSendToCheckout(),
  );

  const baseCartMutation = useBaseMutation(SEND_TO_CART_KEY, httpRepo.useSendtoCart());

  const cartFromGiftMutation = useBaseMutation(
    SEND_TO_CART_FROM_GIFT_KEY,
    httpRepo.useSendToCartFromGift(),
  );

  const submit = (checkoutVariables: Checkout, onSuccess: (data: CheckoutResponse) => void) => {
    baseMutation.submit(
      checkoutVariables,
      { invalidateQueryKeys: [SUBSCRIPTIONS_KEY], onSuccess },
    );
  };

  const submitDirectCart = (cartVariables: Cart, onSuccess: (data: CheckoutResponse) => void) => {
    baseCartMutation.submit(cartVariables, { onSuccess });
  };

  const submitCartFromGift = (cartVariables: Cart, onSuccess: (data: CheckoutResponse) => void) => {
    cartFromGiftMutation.submit(cartVariables, { onSuccess });
  };

  return {
    isSubmitting: baseMutation.isSubmitting,
    submit,
    submitDirectCart,
    submitCartFromGift,
    isCartSubmitting: baseCartMutation.isSubmitting,
    isCartFromGiftSubmitting: cartFromGiftMutation.isSubmitting,
  };
};

const useRedeemEndOfSeasonOffer = () => {
  const mutation = httpRepo.useRedeemEndOfSeasonOffer();

  const baseMutation = useBaseMutation(
    REDEEM_END_OF_SEASON_OFFER_KEY,
    mutation,
  );

  const submit = (offerId: string, onSuccess: (data: CheckoutResponse) => void) => {
    baseMutation.submit(offerId, { onSuccess });
  };

  return { isSubmitting: baseMutation.isSubmitting, submit };
};

const useAddDays = () => {
  const baseMutation = useBaseMutation(
    ADD_DAYS_KEY,
    httpRepo.useAddDays(),
  );

  const submit = (checkoutVariables: Checkout, onSuccess: (data: CheckoutResponse) => void) => {
    baseMutation.submit(
      checkoutVariables,
      { invalidateQueryKeys: [SUBSCRIPTIONS_KEY], onSuccess },
    );
  };

  return {
    isSubmitting: baseMutation.isSubmitting,
    submit,
  };
};

const service = {
  useAddDays,
  useSendPaymentUpdateEmail,
  useSendToCheckout,
  useRedeemEndOfSeasonOffer,
};

export default service;
