import { Subscription } from 'core/domain/model';
import utils from 'core/utils';
import paymentUtils from 'payment/utils';
import CardDetails from 'payment/Components/CardDetails';
import React from 'react';
import AvailablePlanCard from 'renewal/Components/AvailablePlanCard';
import { AvailablePlan } from 'renewal/domain/model/AvailablePlan';
import LoadingButton from 'core/Components/LoadingButton';
import Membership from 'core/domain/model/Membership';

interface RenewCheckoutTemplateProps {
  existingItem: Subscription | Membership | undefined
  plan: AvailablePlan
  checkoutOnClick: () => void
  isSubmitting: boolean
  useNewCardOnClick: () => void
  isUseNewCardSubmitting: boolean
}

const getRenewalDate = (duration: string) => {
  const date = new Date();
  if (duration === '2 years') {
    date.setFullYear(date.getFullYear() + 2);
  } else {
    date.setFullYear(date.getFullYear() + 1);
  }
  return utils.toLongDate(date);
};

const getPaymentMethod = (existingItem: Subscription | Membership) => {
  if ((existingItem as Subscription).paymentMethod) {
    return (existingItem as Subscription).paymentMethod;
  }
  return null;
};

const RenewCheckoutTemplate = ({
  existingItem,
  plan,
  checkoutOnClick,
  isSubmitting,
  useNewCardOnClick,
  isUseNewCardSubmitting,
}
: RenewCheckoutTemplateProps) => {
  if (existingItem === undefined) return null;
  const renewalDate = getRenewalDate(plan.duration);

  const paymentMethod = getPaymentMethod(existingItem);

  // not expired or payal
  const showPaymentDetails = paymentMethod && !paymentUtils.isAlreadyExpired(paymentMethod);

  return (
    <section className="w-full">
      <h5 className="text-24 font-bold text-blue-900">Your New Plan</h5>
      <AvailablePlanCard plan={plan} currentSubscriptionId={existingItem.id} isViewOnly />
      <h5 className="text-24 font-bold text-blue-900">Subtotal</h5>
      <div className="relative bg-white overflow-hidden w-full rounded-12 border border-grey-200 flex flex-col py-24 mt-20">
        <div className="relative overflow-hidden py-12 px-24 md:max-w-1080 text-18">
          <div className="flex items-center border-b border-grey-350 pb-16">
            <h3 className="text-grey-700 grow">Price </h3>
            <p className="text-blue-900 font-semibold leading-none">{plan.currencySymbol}{plan.price}</p>
          </div>
          <div className="flex items-center  border-b border-grey-350 py-16">
            <h3 className="text-grey-700 grow">Charged Every</h3>
            <p className="text-blue-900 font-semibold leading-none">{plan.duration}</p>
          </div>
          <div className="flex items-center border-b border-grey-350 py-16">
            <h3 className="text-grey-700 grow">Renews On</h3>
            <p className="text-blue-900 font-semibold leading-none">{renewalDate}</p>
          </div>
          <div className="flex items-center pt-16">
            <h3 className="text-grey-700 grow">Activates</h3>
            <p className="text-blue-900 font-semibold leading-none">Instantly</p>
          </div>
        </div>
      </div>
      {showPaymentDetails ? (
        <>
          <h5 className="text-24 font-bold text-blue-900 mt-24">Payment Details</h5>
          <div className="relative bg-white overflow-hidden shadow-grey-50%-sm w-full rounded-12 border border-grey-200 flex flex-col py-24 mt-20">
            <div className="relative overflow-hidden py-12 px-24 md:max-w-1080">
              <div className="lg:flex mt-16 items-center text-center gap-16">
                <CardDetails paymentMethod={paymentMethod} />
                <LoadingButton
                  isSubmitting={isSubmitting || isUseNewCardSubmitting}
                  onClick={checkoutOnClick}
                  data-testid="checkout-button"
                  dataId="checkout-with-existing-card-button"
                  dataNewPlanName={plan.productTitle}
                  className="btn--md btn--pri border-2 py-16 px-20 w-full max-w-300 rounded-6 mt-20 lg:mt-0"
                  text="confirm and pay now"
                />
                <LoadingButton
                  isSubmitting={isSubmitting || isUseNewCardSubmitting}
                  onClick={useNewCardOnClick}
                  data-testid="checkout-new-card-button"
                  dataId="checkout-button"
                  dataNewPlanName={plan.productTitle}
                  className="btn--md bg-transparent border-2 border-blue-400 py-16 px-20 text-blue-400 w-full max-w-300  rounded-6 hover:text-white hover:bg-blue-400 mt-20 lg:mt-0"
                  text="use another card"
                />
              </div>
            </div>
          </div>
        </>
      )
        : (
          <div className="w-full mt-16 items-center text-center gap-16">
            <LoadingButton
              isSubmitting={isUseNewCardSubmitting}
              onClick={useNewCardOnClick}
              data-testid="checkout-button"
              dataId="checkout-button"
              dataNewPlanName={plan.productTitle}
              className="btn--md btn--pri border-2 py-16 px-20 w-full max-w-300 rounded-6 mt-20 lg:mt-0"
              text="go to checkout"
            />
          </div>
        )}
    </section>
  );
};

export default RenewCheckoutTemplate;
