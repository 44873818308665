import { useIsMutating, UseMutationResult } from '@tanstack/react-query';
import queryClient from 'core/api/query-client';
import { useErrorModal } from 'core/context/ErrorModalContext';
import { useState } from 'react';
import { To, useNavigate } from 'react-router-dom';

type MutationError = {
  response: {
    data: {
      message: string
    }
  }
};

export type MutationResult = UseMutationResult<any, any, any, any>;
/*
Handles submitting a mutation, opening the error modal.
With the submit function, it is possible to:
- invalidate queries with invalidateQueryKeys parameter
- navigate to a new URL
*/
const useBaseMutation = (
  mutationKey: String,
  mutation: MutationResult,
) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isMutating = useIsMutating([mutationKey]); // prevents erroneous POST requests to /session
  const navigate = useNavigate();

  const errorModal = useErrorModal();
  /**
   * @param {any} variables
   * @param {Object} submissionOptions - options for react-query mutation
   * @property {String[]} invalidateQueryKeys? - react-query query keys to invalidate
   * @property {To} submissionOptions.navigateUrl? - navigate to this URL on success
   * @property {
   * (data:any, successVariables:any, context:any) =>void
   * } onSuccess? - function to run onSuccess of mutation
   * @param {boolean} useDefaultErrorModal - use default error modal. Default is true.
   */
  const submit = (
    variables: any,
    submissionOptions: {
      invalidateQueryKeys?: String[],
      navigateUrl?: To,
      onError?: ((error: MutationError, variables: any, context: any) => void) | undefined,
      onSuccess?: ((data: any, successVariables: any, context: any) => void) | undefined,
    },
    useDefaultErrorModal = true,
  ) => {
    const { invalidateQueryKeys, navigateUrl, onSuccess, onError } = submissionOptions;
    if (isMutating === 0) {
      setIsSubmitting(true);
      mutation.mutate(
        variables,
        {
          onSuccess: (data, successVariables, context) => {
            if (invalidateQueryKeys) {
              invalidateQueryKeys.forEach((key) => {
                queryClient.invalidateQueries([key]);
              });
            }
            if (onSuccess) {
              onSuccess(data, successVariables, context);
            }
            if (navigateUrl) {
              navigate(navigateUrl);
            }
          },
          onError: (error: MutationError, errorVariables, context) => {
            setIsSubmitting(false);
            if (useDefaultErrorModal) errorModal.setOpen();

            if (onError) {
              onError(error, errorVariables, context);
            }
          },
          onSettled: () => {
            setIsSubmitting(false);
          },
        },
      );
    }
  };

  return { isSubmitting, submit };
};

export type { MutationError };

export default useBaseMutation;
