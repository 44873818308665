import LoadingButton from 'core/Components/LoadingButton';
import React from 'react';
import HardwareUpgradeOffer from 'renewal/domain/model/HardwareUpgradeOffer';
import utils from 'core/utils';
import OfferType from 'renewal/domain/model/OfferType';

interface OfferCardProps {
  offer: HardwareUpgradeOffer
  onClick: () => void
}

const OfferCard = ({ offer, onClick } : OfferCardProps) => {
  const isEarlyReneal = offer.type === OfferType.HARDWARE_EARLY_RENEWAL;

  const offerText = isEarlyReneal ? (
    <p className="text-24 md:text-34 md:pr-32">Renew your membership now to
      <span className="text-blue-400 font-bold">{' '}get a free upgrade to
        the newest hardware
      </span>
    </p>
  ) : (
    <p className="text-24 md:text-34 md:pr-32">Claim your
      <span className="text-blue-400 font-bold">{' '} {offer.price === 0 && 'free '}upgrade to
        the newest hardware
      </span>
    </p>
  );

  return (
    <div className="md:flex bg-blue-900 p-24 rounded-12 text-white">
      <div className="mb-24">
        <p className="text-14 font-bold leading-loose caps mb-12">Limited Time Offer*</p>
        {offerText}
        <p className="text-8">*expires {utils.toLongDate(offer.expiresAt)}</p>
      </div>
      <div className="md:min-w-[50%]">
        <p className="text-14 font-body leading-loose caps mb-6 md:mb-12">Updated details</p>
        <div className="rounded-12 py-12 px-12 text-18 bg-blue-850">
          <div className="flex items-center py-4">
            <h3 className="text-grey-500 grow">Pay Now</h3>
            <div className="flex gap-6">
              <p className="font-semibold leading-none">{offer.currencySymbol}{offer.price}</p>
            </div>
          </div>
          <p className="text-grey-500 text-12 text-right -mt-6">{offer.currency !== 'GBP' ? 'Taxes Apply' : null}</p>
          {isEarlyReneal
          && (
            <div className="flex items-center py-4">
              <h3 className="text-grey-500 grow">Renews On</h3>
              <p className="font-semibold leading-none">{offer.nextChargeDate}</p>
            </div>
          )}
        </div>
        <div className="text-center">
          <LoadingButton
            dataId="accept-offer"
            onClick={onClick}
            isSubmitting={false}
            className="btn btn--pri btn--md my-12"
            text="accept offer"
          />
        </div>
      </div>

    </div>
  );
};

interface TemplateProps {
  offer: HardwareUpgradeOffer,
  acceptOnClick: () => void,
}

const NewHardwareOfferTemplate = ({ offer, acceptOnClick }: TemplateProps) => {
  const isEarlyReneal = offer.type === OfferType.HARDWARE_EARLY_RENEWAL;

  return (
    <section className="w-full">
      <p className="text-24 font-bold text-blue-900">{isEarlyReneal ? 'Renew Your Membership' : 'Upgrade Your Hardware'}</p>
      <p className="text-20 font-semibold mb-16">We have an offer for you.</p>
      <OfferCard
        offer={offer}
        onClick={acceptOnClick}
      />
      {/* TODO gather FAQs */}
      {/* <FAQ /> */}

    </section>
  );
};

export default NewHardwareOfferTemplate;
