import React from 'react';
import HardwareUpgradeOffer from 'renewal/domain/model/HardwareUpgradeOffer';

interface AcceptedTemplateProps {
  offer: HardwareUpgradeOffer
  confirmOnClick: () => void
  isSubmitting: boolean
}

const AcceptedTemplate = ({ offer, confirmOnClick, isSubmitting }: AcceptedTemplateProps) => {
  console.log({ offer, confirmOnClick, isSubmitting });
  return (<div>AcceptedTemplate</div>);
};

export default AcceptedTemplate;
