import React from 'react';
import { ReactComponent as MagicEmail } from 'svg/magic-email-sent.svg';

interface AfterSubmitProps {
  email: string,
  reset: () => void
}

const AfterSubmit = ({ email, reset }: AfterSubmitProps) => (
  <section className="grow bg-grey-200 py-48 text-center">
    <div className="container container--default">
      <MagicEmail className="mx-auto mb-24" />
      <p className="h4 font-bold text-blue-900 -mb-8">Check your email</p>
      <p className="h5 text-blue-900 mb-24 md:mb-12 mx-auto">for the magic link</p>
      <p className="text-grey-500 font-weight-100 mb-24 md:mb-12 mx-auto">if <span className="font-bold">{email}</span> is registered with us you&apos;ll receive a magic link to log in.</p>
      <button type="button" onClick={reset} className="block btn btn--sec btn--lg mx-auto w-300 mt-24">Try another email address </button>
    </div>
  </section>
//   ) : (
//     <div className="md:max-w-500">
//       <div className="h2 text-blue-900">
//         Something went wrong.
//       </div>
//       <div className="h-4 text-blue-900">
//         We couldn&apos;t find an account with {email}.
//       </div>
//     </div>
//   )
);

export default AfterSubmit;
