import React, { useState } from 'react';
import CardDetails from 'payment/Components/CardDetails';
import coreService from 'core/domain/interface/CoreService';
import { Link } from 'react-router-dom';
import { Subscription } from 'core/domain/model';
import { AvailablePlan } from 'renewal/domain/model/AvailablePlan';
import GenericModal from 'core/Components/GenericModal';
import { ReactComponent as TickIcon } from 'svg/tick.svg';
import { useFlags } from 'featureFlags/FlagContext';

interface UpdateSectionProps {
  subscription: Subscription
  isOwner: boolean
  ownerEmail: string
  isActive: boolean
  isCurrent: boolean
  currentPlan: AvailablePlan | undefined
  isReactivateEnabled: boolean
}

const NotThePlanOwner = ({ subscription, ownerEmail } : { subscription: Subscription,
  ownerEmail: string }) => {
  const sendLinkToPlanOwner = coreService.useSendLinkToPlanManager();
  const [isOpen, setIsOpen] = useState(false);
  const { id } = subscription;

  const onClick = () => sendLinkToPlanOwner.submit(id, () => { setIsOpen(true); });
  return (
    <>
      <GenericModal isOpen={isOpen}>
        <div className="flex w-full h-full justify-center items-baseline">
          <TickIcon />
        </div>
        <p className="text-24 text-blue-900 font-bold my-16">Request Sent</p>
        <p className="text-18 text-blue-900 font-semibold mb-12">{ownerEmail}</p>
        <p className="text-16 text-blue-900 mb-12">We have just sent a request to this email address. Click the link in the email to give access to manage this plan.
        </p>
        <button type="button" onClick={() => setIsOpen(false)} className="btn btn--pri btn--lg mb-12">
          DONE
        </button>
      </GenericModal>
      <p className="text-left">To make changes to this plan, please request a login link to the plan manager&apos;s email, <span className="text-blue-900 font-extrabold">{ownerEmail}</span>
      </p>
      <button
        type="button"
        data-testid="send-plan-owner-email"
        data-id="send-link-plan-button"
        data-subscription-id={subscription.id}
        className="btn bg-transparent border-2 border-blue-400 py-16 px-20 text-blue-400 w-full max-w-300 rounded-6 hover:text-white hover:bg-blue-400 mt-20 lg:mt-0"
        onClick={onClick}
      >
        send link to plan manager
      </button>
    </>
  );
};

const SubscriptionUpdateSection = ({
  subscription, isOwner, ownerEmail, isActive, isCurrent, isReactivateEnabled, currentPlan,
}
: UpdateSectionProps) => {
  let content = null;
  const flags = useFlags();
  const ism1UpgradeActive = flags.getIsActive('m1-upgrade');

  if (!isOwner) {
    content = <NotThePlanOwner ownerEmail={ownerEmail} subscription={subscription} />;
  }

  // Link to Manage page for active plan
  if (!isCurrent && isOwner && isActive) {
    content = (
      <>
        <CardDetails paymentMethod={subscription.paymentMethod} error={subscription.errorCode} />
        <div className="">
          <Link
            to={`/renewal/${subscription.id}`}
            data-testid="manage-button"
            data-id="manage-plan-button"
            data-subscription-id={subscription.id}
            className="btn bg-transparent border-2 border-blue-400 py-16 px-20 text-blue-400 w-full max-w-300  rounded-6 hover:text-white hover:bg-blue-400 mt-20 lg:mt-0"
          >
            MANAGE PLAN
          </Link>
          {ism1UpgradeActive
      && (
        <div className="max-w-300 md:w-300 md:mt-12 mt-6 mx-auto">
          <Link
            to="/renewal/upgrade"
            data-testid="upgrade-button"
            className="btn bg-blue-900 border-2 border-blue-400 py-16 px-20 text-white w-full max-w-300  rounded-6 hover:text-white hover:bg-blue-400 mt-20 lg:mt-0"
          >
            UPGRADE HARDWARE
          </Link>
        </div>
      )}
        </div>

      </>
    );
  }

  // Show renew plan button for any non-active PlanCard which links to Manage Page
  // if this plan isCurrent (is being viewed) link to Checkout Page
  if (!isActive && isOwner && isReactivateEnabled) {
    content = (
      <>
        <CardDetails paymentMethod={subscription.paymentMethod} />
        <Link
          to={isCurrent ? `/payment/${subscription.id}/${currentPlan?.id}/renew-checkout` : `/renewal/${subscription.id}`} // TODO add payment route
          data-testid="manage-button"
          data-id="renew-plan-button"
          data-subscription-id={subscription.id}
          className="btn btn--pri border-2 py-16 px-20 w-full max-w-300 rounded-6 mt-20 lg:mt-0"
        >
          RENEW PLAN
        </Link>
      </>
    );
  }

  return (
    <div className="lg:flex mt-16 items-center text-center gap-16">
      {content}
    </div>
  );
};

export default SubscriptionUpdateSection;
