import { AvailablePlan } from 'renewal/domain/model/AvailablePlan';
import currency from 'core/hooks/currency';
import { UNLIMITED_SKI_DAYS } from 'core/domain/const';
import Sku from 'renewal/domain/model/Sku';
import Offer from 'renewal/domain/model/Offer';
import utils from 'core/utils';
import OfferType from 'renewal/domain/model/OfferType';
import { UpsellPlan } from 'renewal/domain/model/UpsellPlan';
import HardwareUpgradeOffer from 'renewal/domain/model/HardwareUpgradeOffer';
import { AvailablePlanDTO } from './dto/AvailablePlanDTO';
import SkuDTO from './dto/SkuDTO';
import OfferDTO, { DurationOfferDTO, PercentageOfferDTO } from './dto/OfferDTO';
import OfferTypeDTO from './dto/OfferTypeDTO';
import { UpsellPlanDTO } from './dto/UpsellPlanDTO';
import HardwareUpgradeOfferDTO from './dto/HardwareUpgradeOfferDTO';
import HardwareUpgradeOfferTypeDTO from './dto/HardwareUpgradeOfferTypeDTO';

const addOneYear = (date: Date) => new Date(
  date.getFullYear() + 1,
  date.getMonth(),
  date.getDate(),
);

const toDomainAvailablePlan = (dto : AvailablePlanDTO)
: AvailablePlan => ({
  id: dto.id,
  productTitle: dto.description,
  price: parseInt(currency.useFormatMoneyPartsValue(
    dto.price,
    dto.currency,
  ), 10),
  currency: dto.currency,
  currencySymbol: currency.useFormatMoneyPartsSymbol(
    dto.price,
    dto.currency,
  ),
  skiDays: dto.day_limit ? dto.day_limit.toString() : UNLIMITED_SKI_DAYS,
  duration: dto.duration,
  isCurrent: dto.current_plan,
});

const toDomainSku = (dto: SkuDTO) : Sku => ({
  title: dto.title,
  membershipDuration: dto.membership_duration,
  description: dto.description,
  startOnRedemption: dto.start_on_redemption,
  days: dto.day_limit,
});

const toDomainOffer = (dto: OfferDTO): Offer => {
  const offer: Offer = {
    id: dto.uuid,
    type: dto?.offer.type === OfferTypeDTO.EXTRA_DURATION
      ? OfferType.DURATION : OfferType.PERCENTAGE,
    price: parseInt(currency.useFormatMoneyPartsValue(
      dto.price.toString(),
      dto.currency,
    ), 10),
    title: dto?.title,
    currency: dto?.currency,
    currencySymbol: currency.useFormatMoneyPartsSymbol(
      dto.price.toString(),
      dto.currency,
    ),
    expiresAt: dto?.expires_at,
    startDate: utils.toLongDate(new Date()),
    nextChargeDate:
    utils.toLongDate(dto.source.next_charge_scheduled_at || addOneYear(new Date())),
  };

  if (offer.type === OfferType.PERCENTAGE) {
    offer.discountedPrice = (dto.offer as PercentageOfferDTO).discounted_price;
    offer.discount = (dto.offer as PercentageOfferDTO).discount;
  }
  if (offer.type === OfferType.DURATION) {
    offer.duration = (dto.offer as DurationOfferDTO).duration;
  }
  return offer;
};

const toDomainHardwareUpgradeOffer = (dto: HardwareUpgradeOfferDTO): HardwareUpgradeOffer => {
  const offer: HardwareUpgradeOffer = {
    id: dto.uuid,
    type: dto.offer.type === HardwareUpgradeOfferTypeDTO.EARLY_RENEWAL
      ? OfferType.HARDWARE_EARLY_RENEWAL : OfferType.HARDWARE_ONLY,
    price: dto.offer.price,
    expiresAt: dto.expires_at,
    currency: dto.currency,
    currencySymbol: currency.useFormatMoneyPartsSymbol(
      dto.offer.price.toString(),
      dto.currency,
    ),
    title: dto.subscription.title,
    startDate: utils.toLongDate(new Date()),
    nextChargeDate:
    utils.toLongDate(dto.subscription.next_charge_scheduled_at || addOneYear(new Date())),

  };
  return offer;
};

const toDomainUpsellPlan = (dto: UpsellPlanDTO) : UpsellPlan => ({
  id: dto.id,
  productTitle: dto.description,
  price: parseInt(currency.useFormatMoneyPartsValue(
    dto.price,
    dto.currency,
  ), 10),
  currency: dto.currency,
  currencySymbol: currency.useFormatMoneyPartsSymbol(
    dto.price,
    dto.currency,
  ),
  skiDays: dto.day_limit ? dto.day_limit.toString() : UNLIMITED_SKI_DAYS,
  duration: dto.duration,
});

const mapper = {
  toDomainAvailablePlan,
  toDomainSku,
  toDomainOffer,
  toDomainHardwareUpgradeOffer,
  toDomainUpsellPlan,
};

export default mapper;
