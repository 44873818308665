import React from 'react';
import { User } from 'core/auth/types';
import EmptyProfilePic from 'core/Components/EmptyProfilePic';

const getDisplayName = (firstName: string) => {
  let displayName = `${firstName[0].toUpperCase()}${firstName.slice(1)}'`;
  if (firstName.slice(-1) !== 's') {
    displayName = displayName.concat('s');
  }
  return displayName;
};

const UserSection = ({ user, code } : { user: User | null, code: string }) => {
  if (user) {
    return (
      <>
        {user.photoUrl ? <img className="h-24 w-24 rounded-full" src={user.photoUrl} alt="User profile" /> : <EmptyProfilePic firstName={user.firstName} lastName={user.lastName} isSmall />}

        <span className="text-24 text-blue-900 font-bold">
          {getDisplayName(user.firstName)} plan
        </span>
      </>
    );
  }
  return (
    <div className="block text-left">
      <p className="text-18">No one is using this plan yet</p>
      {code && (
        <p className="text-24 text-blue-900 font-bold">
          Activate Now: {code}
        </p>
      )}
    </div>
  );
};

export default UserSection;
