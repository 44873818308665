import { useMutation, useQuery } from '@tanstack/react-query';
import apiClient from 'core/api/api-client';
import { CancelData, CancelVariables } from 'renewal/api/dto/Cancel';
import { AvailablePlanResponse } from './dto/AvailablePlanDTO';
import ChooseNewPlan from './dto/ChooseNewPlan';
import DelayPlan from './dto/DelayPlan';
import { OfferResponse } from './dto/OfferDTO';
import { UpsellPlanResponse } from './dto/UpsellPlanDTO';
import { HardwareUpgradeOfferResponse } from './dto/HardwareUpgradeOfferDTO';

const AVAILABLE_PLANS_KEY = 'availablePlans';
const UPSELL_PLANS_KEY = 'upsellPlans';
const END_OF_SEASON_OFFER_KEY = 'endOfSeasonOffer';
const HARDWARE_UPGRADE_OFFER_KEY = 'hardware-upgrade-offer';
const CHOOSE_NEW_PLAN_KEY = 'chooseNewPlan';
const DELAY_PLAN_KEY = 'delayPlan';

const CANCEL_SUBSCRIPTION_KEY = 'cancel';

const useFetchAvailablePlans = (entityId: string, isSubscription: boolean) => useQuery(
  [AVAILABLE_PLANS_KEY + entityId],
  async () => {
    if (isSubscription) {
      const response = await apiClient.get<AvailablePlanResponse>(`subscriptions/${entityId}/variants`);
      return response.data;
    }
    const response = await apiClient.get<AvailablePlanResponse>(`memberships/${entityId}/variants`);
    return response.data;
  },
  { enabled: !!entityId },
);

const useFetchUpsellPlans = (membershipId?: string) => useQuery(
  [UPSELL_PLANS_KEY + membershipId],
  async () => {
    const response = await apiClient.get<UpsellPlanResponse>(`memberships/${membershipId}/upsell_variants`);
    return response.data;
  },
  { enabled: !!membershipId },
);

const useFetchEndOfSeasonOffer = () => useQuery(
  [END_OF_SEASON_OFFER_KEY],
  async () => {
    const response = await apiClient.get<OfferResponse>('end_of_season_offer');
    return response.data;
  },
);

const useFetchHardwareUpgradeOffer = () => useQuery(
  [HARDWARE_UPGRADE_OFFER_KEY],
  async () => {
    const response = await apiClient.get<HardwareUpgradeOfferResponse>('hardware_upgrade_offer');
    return response.data;
  },
);

const sendChooseNewPlan = (variables: ChooseNewPlan) => apiClient.put(`subscriptions/${variables.subscriptionId}/variant`, { variantId: parseInt(variables.variantId, 10) });

const useChooseNewPlan = () => useMutation(
  sendChooseNewPlan,
  { mutationKey: [CHOOSE_NEW_PLAN_KEY] },
);

const sendDelayPlan = (variables: DelayPlan) => apiClient.put(`subscriptions/${variables.subscriptionId}/renewal_date`, variables.data);

const useDelayPlan = () => useMutation(
  sendDelayPlan,
  { mutationKey: [DELAY_PLAN_KEY] },
);

const cancelfunction = (variables: CancelVariables) => apiClient.post<any, any, CancelData>(`subscriptions/${variables.id}/cancel`, variables.data);

const useCancelSubscription = () => useMutation(
  cancelfunction,
  { mutationKey: [CANCEL_SUBSCRIPTION_KEY] },
);

export { CHOOSE_NEW_PLAN_KEY, AVAILABLE_PLANS_KEY, DELAY_PLAN_KEY, CANCEL_SUBSCRIPTION_KEY };

const service = {
  useFetchAvailablePlans,
  useFetchUpsellPlans,
  useFetchEndOfSeasonOffer,
  useFetchHardwareUpgradeOffer,
  useChooseNewPlan,
  useDelayPlan,
  useCancelSubscription,
};

export default service;
