import React, { useState } from 'react';
import RootPage from 'core/Pages/RootPage';
import RenewalService from 'renewal/domain/interface/RenewalService';
import LoadingIndicator from 'core/Components/LoadingIndicator';
import { useErrorModal } from 'core/context/ErrorModalContext';
import { useNavigate } from 'react-router-dom';
import NewHardwareOfferTemplate from './NewHardwareOfferTemplate';
import AcceptedTemplate from './AcceptedTemplate';

const NewHardwareOffer = () => {
  const [accepted, setAccepted] = useState(false);
  const { data, isLoading } = RenewalService.useHardwareUpgradeOffer();
  const [isSubmitting, setIsSubmitting] = useState(false); // TODO add mutation to redeem offer
  const errorModal = useErrorModal();
  const nav = useNavigate();
  if (!data && isLoading) {
    return <LoadingIndicator />;
  }
  if (!data) {
    errorModal.setText('Error fetching offer');
    errorModal.setOpen();
    nav('/');
    return null;
  }

  const acceptOnClick = () => {
    setAccepted(true);
  };

  // TODO add mutation to redeem offer
  const confirmOnClick = () => {
    setIsSubmitting(true);
    console.log('confirmed');
  };

  return (
    <RootPage>
      { accepted ? (
        <AcceptedTemplate
          offer={data}
          isSubmitting={isSubmitting}
          confirmOnClick={confirmOnClick}
        />
      )
        : (
          <NewHardwareOfferTemplate
            offer={data}
            acceptOnClick={acceptOnClick}
          />
        )}
    </RootPage>
  );
};

export default NewHardwareOffer;
