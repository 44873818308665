import React from 'react';
import { Link } from 'react-router-dom';

const SingletonUpdateSection = ({ membershipId } : { membershipId: string }) => (
  <div className="text-center lg:text-right">
    <Link
      to={`/renewal/${membershipId}`}
      data-testid="manage-button"
      className="btn bg-transparent border-2 border-blue-400 py-16 px-20 text-blue-400 w-full max-w-300  rounded-6 hover:text-white hover:bg-blue-400 mt-20 lg:mt-0"
    >
      MANAGE PLAN
    </Link>
  </div>
);

export default SingletonUpdateSection;
