import React from 'react';
import { SingletonPlanCard } from 'renewal/Components/PlanCard';
import Membership from 'core/domain/model/Membership';
import MembershipType from 'core/domain/model/MembershipType';
import { AvailablePlan } from 'renewal/domain/model/AvailablePlan';
import AvailablePlansSection from 'renewal/Components/AvailablePlansSection';
import { UNLIMITED_SKI_DAYS } from 'core/domain/const';
import { useFlags } from 'featureFlags/FlagContext';
import AddMoreDaysButton from 'renewal/Components/AddMoreDaysButton';

interface SingletonPlanTemplateProps {
  membership: Membership
  availablePlans: AvailablePlan[]
  dailyPrice: string
  isUpsellPlans: boolean
}

const SingletonPlanTemplate = (props : SingletonPlanTemplateProps) => {
  const flags = useFlags();
  const isAddMoreDaysEnabled = flags.getIsActive('add-more-days');

  const { membership, availablePlans, dailyPrice, isUpsellPlans } = props;

  const isActive = membership.endDate === 'N/A' || new Date(membership.endDate) > new Date();

  const isDaily = membership?.skiDays !== UNLIMITED_SKI_DAYS;

  return (
    <section className="w-full">
      <div className="container container--default">
        <h5 className="text-24 font-bold text-blue-900">Manage Plan</h5>
        <SingletonPlanCard
          membership={membership}
          isCurrent
        />
        {isUpsellPlans && isDaily && isAddMoreDaysEnabled ? (
          <AddMoreDaysButton membership={membership} dailyPrice={dailyPrice} />
        ) : null}
        { membership.type === MembershipType.GIFT && !isActive
      && (
        <div className="mt-48">
          <h5 className="text-18 font-bold text-blue-900">Buy a New Plan</h5>
          <AvailablePlansSection
            availablePlans={availablePlans}
            currentSubscriptionId={membership.id}
            isCurrentPlanActive={isActive}
          />
        </div>
      ) }
      </div>
    </section>
  );
};

SingletonPlanTemplate.defaultProps = { };

export default SingletonPlanTemplate;
